<template>
  <div class="pt-2">
    <div v-if="digitalPaymentMethods.length > 0">
      <ion-label class="text pl-2 fs-3 fw-600 text-gray-700">{{ labelDigitalPayment }}</ion-label>
      <ion-grid class="pt-3">
        <ion-radio-group :value="digitalCardIdSelected" @change="onSelectDigitalCard">
          <ion-row
            class="mb-2"
            v-for="method in digitalPaymentMethods"
            :key="method.id"
            @click="onSelectType(method.value, method.id)"
          >
            <ion-col size="auto" class="pt-2">
              <ion-img :src="method.image" />
            </ion-col>
            <ion-col class="d-flex align-center justify-space-between underline">
              <div>
                <ion-text class="text-gray-700 mb-1 fw-600">{{ method.name }}</ion-text>
                <div class="text-gray-500 mt-1 fs-2">{{ method.exp }}</div>
                <div
                  v-if="method.isDefault"
                  class="fs-1 fw-500 bg-primary text-center f-white bor-15 my-1 w-content px-2"
                >
                  {{ $t('default') }}
                </div>
              </div>
              <ion-radio
                v-if="isFullyPaidCustBalance || (totalPaidAmount === 0 && paymentType === 7)"
                :disabled="true"
                class="mb-1"
              ></ion-radio>
              <ion-radio v-else :value="method.id" class="mb-1"></ion-radio>
            </ion-col>
          </ion-row>
        </ion-radio-group>
      </ion-grid>
    </div>
    <div v-if="digitalPaymentMethods.length > 0" class="spacer"></div>
    <div>
      <ion-label class="text pl-2 fs-3 mb-3 fw-600 text-gray-700">{{ labelOtherPayment }}</ion-label>
      <ion-grid class="pt-3">
        <ion-radio-group v-model="paymentType">
          <ion-row
            class="mb-2 ion-align-items-center"
            v-for="method in otherPaymentMethods"
            :key="method.id"
            @click="onSelectType(method.value, method.id)"
          >
            <ion-col size="auto">
              <ion-img :src="method.image" />
            </ion-col>
            <ion-col class="d-flex align-center justify-space-between underline">
              <ion-text class="text-gray-700 mb-1 fw-600">{{ $t(`${method.name}`) }}</ion-text>
              <ion-radio
                v-if="isFullyPaidCustBalance || (totalPaidAmount === 0 && paymentType === 7)"
                :disabled="true"
                class="mb-1"
              ></ion-radio>
              <ion-radio v-else :value="method.value" class="mb-1"></ion-radio>
            </ion-col>
          </ion-row>
        </ion-radio-group>
      </ion-grid>
    </div>
  </div>
</template>
<script>
import { CARD_IMAGE_BRAND, CARD_INVOICES_IMAGE, INVOICE_PAYMENT_TYPE } from '@/modules/shared/constants';
import { addCircleOutline, cardOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'card-payment',
  props: {
    totalPaidAmount: {
      type: Number,
      default: 0
    },
    userTypeId: {
      type: Number,
      default: 0
    },
    listCards: {
      type: Array,
      default: () => []
    },
    isSgMyUser: {
      type: Boolean,
      default: false
    },
    isFullyPaidCustBalance: {
      type: Boolean,
      default: false
    }
  },
  emits: ['onChangePaymentType'],
  setup(props) {
    const { t } = useI18n();
    const labelDigitalPayment = t('digital_payment_method');
    const labelOtherPayment = t('other_payment_method');
    const digitalCardIdSelected = ref(null);
    const {
      BANK_TRANSFER,
      CASH_DEPOSIT,
      CASH_PAYMENT_COLLECTION,
      CHEQUE,
      CREDIT_CARD
    } = INVOICE_PAYMENT_TYPE;
    const { BANK_IMAGE, CHEQUE_IMAGE, CASH_DEPOSIT_IMAGE, CASH_PAYMENT_IMAGE } = CARD_INVOICES_IMAGE;
    const paymentType = ref(null);
    const notSalesUser = ref(props.userTypeId !== 7);
    const digitalPaymentMethods = ref(
      [...props.listCards].map((item) => {
        return {
          id: item.id,
          name: item.brand,
          image: CARD_IMAGE_BRAND[item.brand.toUpperCase()],
          value: CREDIT_CARD,
          exp: `*${item.last4} ${item.exp_month}/${item.exp_year}`,
          isDefault: item.isDefault
        };
      })
    );
    const otherPaymentMethods = ref(
      [
        {
          id: 1,
          name: 'bank_transfer',
          value: BANK_TRANSFER,
          image: BANK_IMAGE
        },
        notSalesUser.value && {
          id: 2,
          name: 'cheque',
          value: CHEQUE,
          image: CHEQUE_IMAGE
        },
        notSalesUser.value && {
          id: 3,
          name: 'cash_payment_collection',
          value: CASH_PAYMENT_COLLECTION,
          image: CASH_PAYMENT_IMAGE
        },
        {
          id: 4,
          name: 'cash_deposit',
          value: CASH_DEPOSIT,
          image: CASH_DEPOSIT_IMAGE
        }
      ].filter(Boolean)
    );
    const onSelectType = (value, digitalId) => {
      if (props.isFullyPaidCustBalance || (props.totalPaidAmount === 0 && paymentType.value === 7)) {
        return;
      }
      paymentType.value = value;
      digitalCardIdSelected.value = digitalId;
    };
    const onSelectDigitalCard = (value) => {
      if (props.isFullyPaidCustBalance || (props.totalPaidAmount === 0 && paymentType.value === 7)) {
        return;
      }
      digitalCardIdSelected.value = value;
    };
    return {
      labelDigitalPayment,
      labelOtherPayment,
      paymentType,
      digitalCardIdSelected,
      onSelectType,
      digitalPaymentMethods,
      otherPaymentMethods,
      cardOutline,
      addCircleOutline,
      onSelectDigitalCard
    };
  },
  watch: {
    paymentType() {
      this.$emit(
        'onChangePaymentType',
        this.isFullyPaidCustBalance ? INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT : this.paymentType,
        this.digitalCardIdSelected
      );
    },
    digitalCardIdSelected() {
      this.$emit(
        'onChangePaymentType',
        this.isFullyPaidCustBalance ? INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT : this.paymentType,
        this.digitalCardIdSelected
      );
    }
  }
});
</script>
<style lang="scss" scoped>
ion-img {
  width: 2rem;
}
.flex-row {
  flex-direction: row;
}
.form-text {
  & > div {
    width: 100%;
    & > .form-card {
      width: 100%;
      display: flex;
      padding: 15px 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
ion-icon {
  font-size: 25px;
  float: right;
}

.card-badge {
  color: white;
  background: #04565a;
  padding: 2px 4px;
  font-size: 10px;
  border-radius: 7px;
  opacity: 0.9;
}
.underline {
  border-bottom: 1px solid #cfcfcf;
}

.flex-basis-90 {
  flex-basis: 90%;
}
.f-white {
  color: #fff;
}
.bor-15 {
  border-radius: 15px;
}
.w-content {
  width: fit-content;
}
</style>
